// store

const state = {
  client: null,
  keyboard: null
}

const mutations = {
  SET_CLIENT(state, client) {
    state.client = client
  },
  SET_KEYBOARD(state, keyboard) {
    state.keyboard = keyboard
  }
}
export default {
  namespaced: true,
  state,
  mutations
}
