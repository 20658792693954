import { addJobs } from '@/api/file'
import { getBaiduDisk } from '@/api/task'
import store from '@/store'
import { ElMessage } from 'element-plus'
import Guacamole from 'guacamole-common-js'
let timer = null
const state = {
  client: null,
  jobId: null,
  loading: false,
  queue: 'gui',
  keyboard: null,
  canvasWidth: '1920',
  canvasHeight: '1040',
  isCloseWindow: false,
  idState: null,
  // 重连ws次数
  reconnectTimes: 0
}
const actions = {
  // 创建vnc作业
  createVncJob({ dispatch, commit, state }, data) {
    return new Promise((resolve, reject) => {
      // dispatch('queryQueue').then((queue) => {
      const params = {
        'app': 'baidudisk',
        'coupon_id': '',
        'script': '',
        'tasks': 1,
        'tasks_per_node': 1,
        'nodes': [1, 1],
        'partition': state.queue,
        'gpus': 0,
        'jobType': 1,
        'name': 'baidudisk',
        'version': '',
        'current_working_directory': '/public/home/' + store.getters.name,
        'time_limit': null,
        'exclusive': null
      }
      addJobs(params).then((response) => {
        if (response.meta.status === 200) {
          resolve()
          commit('SET_JOB_ID', response.data.job_id)
        } else {
          if (response.meta.status === 5005) {
            reject()
            ElMessage.error('余额不足！')
          } else {
            reject()
            ElMessage.error(response.meta.msg)
          }
        }
      }).catch(error => {
        reject(error)
      })
      // })
    })
  },
  // 判断作业状态
  checkJobStatus({ dispatch, commit, state }, data) {
    return new Promise((resolve, reject) => {
      state.isCloseWindow = false
      commit('SET_LOADING', true)
      // 如果有jobId则直接查询作业状态，否则先创建作业
      getBaiduDisk().then(response => {
        if (response.data.length === 0) {
          // 作业不存在，创建作业
          dispatch('createVncJob').then(() => {
            // 重新去判断作业状态，
            timer = setInterval(() => {
              getBaiduDisk().then(response => {
                if (response.data.length >= 1) {
                  commit('SET_LOADING', false)
                  commit('SET_STATE', response.data[0].state)
                  commit('SET_JOB_ID', response.data[0].idJob)
                  if (response.data[0].state === 'JOB_RUNNING') {
                    dispatch('connectWs')
                    clearInterval(timer)
                  }
                  resolve()
                }
              })
            }, 3000)
          })
        } else if (response.data.length >= 1 && response.data[0].state === 'JOB_RUNNING') {
          commit('SET_JOB_ID', response.data[0].idJob)
          commit('SET_STATE', response.data[0].state)
          dispatch('connectWs')
          resolve()
        } else if (response.data.length >= 1) {
          commit('SET_LOADING', false)
          commit('SET_STATE', response.data[0].state)
          timer = setInterval(() => {
            getBaiduDisk().then(response => {
              if (response.data.length >= 1 && response.data[0].state === 'JOB_RUNNING') {
                commit('SET_JOB_ID', response.data[0].idJob)
                commit('SET_STATE', response.data[0].state)
                dispatch('connectWs')
                clearInterval(timer)
              } else if (response.data.length === 0) {
                clearInterval(timer)
              }
            })
          }, 3000)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 连接ws
  connectWs({ commit, dispatch, state }, data) {
    const idJob = state.jobId
    const token = sessionStorage.desktopToken
    const connect = 'height=768&width=1024&id=' + idJob + '&token=' + token + '&regionId=' + window.localStorage.regionId
    var socket
    var ishttps = document.location.protocol == 'https:'
    if (ishttps) {
      socket = 'wss:' + location.host + '/vncSocket/ws/vnc/webSocket'
    } else {
      socket = 'ws:' + location.host + '/vncSocket/ws/vnc/webSocket'
    }
    const tunnels = new Guacamole.WebSocketTunnel(socket)
    var client = new Guacamole.Client(tunnels)
    tunnels.onstatechange = function(e) {
      if (!state.isCloseWindow && e === 2 && state.reconnectTimes < 3) {
        // 如果
        state.reconnectTimes++
        setTimeout(() => {
          dispatch('connectWs')
        }, 3000)
      } else if (e === 1) {
        // 连接成功
        commit('SET_LOADING', false)
      }
    }
    commit('SET_CLIENT', client)
    document.getElementsByClassName('baidunet-work-disk')[0].appendChild(client.getDisplay().getElement())
    document.getElementsByTagName('canvas').item(0).style = 'z-index: 1'
    document.getElementsByTagName('canvas').item(1).style = 'z-index: 1'
    document.getElementsByTagName('canvas').item(0).style.width = state.canvasWidth + 'px'
    document.getElementsByTagName('canvas').item(0).style.height = state.canvasHeight + 'px'
    client.connect(connect)
    var canvasElements = document.getElementsByTagName('canvas')
    for (var i = 0; i < canvasElements.length; i++) {
      const currentCanvas = canvasElements[i]
      currentCanvas.style = 'z-index: 1'
    }
    var mouse = new Guacamole.Mouse(client.getDisplay().getElement())
    mouse.onmousedown =
        mouse.onmouseup =
            mouse.onmousemove = function(mouseState) {
              if (!state.keyboard.onkeydown) {
                state.keyboard.onkeydown = function(keysym) {
                  client.sendKeyEvent(1, keysym)
                }

                state.keyboard.onkeyup = function(keysym) {
                  client.sendKeyEvent(0, keysym)
                }
              }
              client.sendMouseState(mouseState)
            }
    // Keyboard
    dispatch('addKeyboardListeners', client)
  },
  addKeyboardListeners({ commit, state }, client) {
    if (state.keyboard) {
      return
    }
    const keyboard = new Guacamole.Keyboard(document)
    keyboard.onkeydown = function(keysym) {
      client.sendKeyEvent(1, keysym)
    }
    keyboard.onkeyup = function(keysym) {
      client.sendKeyEvent(0, keysym)
    }
    commit('SET_KEYBOARD', keyboard)
  },
  removeKeyboardListeners({ commit }) {
    if (state.keyboard) {
      state.keyboard.onkeydown = null
      state.keyboard.onkeyup = null
      commit('SET_KEYBOARD', null)
    }
  },
  // 断开连接,清空作业
  closeWs({ commit, dispatch, state }, data) {
    state.isCloseWindow = true
    state.reconnectTimes = 0
    if (state.client) {
      state.client.disconnect()
    }
    if (timer) {
      clearInterval(timer)
    }
    dispatch('removeKeyboardListeners')
    commit('SET_JOB_ID', null)
    commit('SET_CLIENT', null)
    commit('SET_LOADING', false)
    commit('SET_STATE', null)
  }
}
const mutations = {
  SET_QUEUE(state, data) {
    state.queue = data
  },
  SET_JOB_ID(state, data) {
    state.jobId = data
  },
  SET_CLIENT(state, data) {
    state.client = data
  },
  SET_KEYBOARD(state, data) {
    state.keyboard = data
  },
  SET_LOADING(state, data) {
    state.loading = data
  },
  SET_STATE(state, data) {
    state.idState = data
  }
}
export default {
  state,
  actions,
  mutations,
  namespaced: true
}
