import request from '@/utils/requestEhpc'
const slurm = '/service-slurm-provider/v1'
const remote = '/service-remote-provider'
// /jobs/baidudisk/account
export function getBaiduDisk() {
  return request({
    url: slurm + '/jobs/baidudisk/account',
    method: 'get'
  })
}
// 配额价格查询
export function getQuotaPrice(data) {
  return request({
    url: slurm + '/quota/real',
    method: 'get',
    params: data
  })
}
// 设置所有磁盘配额详情
export function getQuotaDeatil() {
  return request({
    url: slurm + '/quota/all',
    method: 'get'
  })
}
// 根据id查询磁盘配额详情
export function getQuotaDeatilById(data) {
  return request({
    url: slurm + '/quota/idList',
    method: 'get',
    params: data
  })
}
// 配额回调
export function getQuotaStates(data) {
  return request({
    url: slurm + '/quota/states',
    method: 'get',
    params: data
  })
}
// 获取用户磁盘配额
export function getQuota() {
  return request({
    url: slurm + '/quota',
    method: 'get'
  })
}

// 设置用户磁盘配额
export function setQuota(data) {
  return request({
    url: slurm + '/quota/user',
    method: 'post',
    data
  })
}
// 获取历史作业
export function jobList(pageNum, pageSize, keyWord) {
  return request({
    url: slurm + '/jobs/db?pagenum=' + pageNum + '&pagesize=' + pageSize + '&keyWord=' + keyWord,
    method: 'get'
  })
}
// 停止作业
export function stopJobs(id) {
  return request({
    url: slurm + '/jobs/' + id,
    method: 'delete'
  })
}
// 获取vnc服务状态
export function queryVncStatus(id) {
  return request({
    url: remote + '/vncserver/' + id,
    method: 'get'
  })
}

// 作业状态信息统计-工作台饼图 T
export function getJobsStatus() {
  return request({
    url: slurm + '/jobs/stat',
    method: 'get'
  })
}

export function getTodayJobUsage() {
  return request({
    url: slurm + '/jobs/usage',
    method: 'get'
  })
}
// 获取运行中作业数量  T
export function getRunningJobCount() {
  return request({
    url: slurm + '/jobs/running/account',
    method: 'get'
  })
}
// 作业所在节点的CPU和内存利用率
export function getJobNodeUsage(time, workID) {
  return request({
    url: slurm + '/nodes/cluster/usage/workid',
    method: 'get',
    params: {
      time,
      workID
    }
  })
}

// 查看作业详情V2
export function getJobDetail(id) {
  return request({
    url: slurm + '/jobs/' + id + '/new',
    method: 'get'
  })
}
// 挂起恢复作业状态
export function suspendJob(id, action) {
  return request({
    url: slurm + '/jobs/' + id + '/signal',
    method: 'delete',
    params: {
      action
    }
  })
}
